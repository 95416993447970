import * as React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import _ from 'lodash';
import {
  PostDetailsWrapper,
  PostTitle,
  PostDate,
  PostPreview,
  PostDescriptionWrapper,
  HonbunWrapper,
  PostDescription,
  PostTags,
} from './post-details.style';

import CesiumDiv from "../../pages/cesiumDiv";
import Sdgpanel from "../../containers/sdgs/panel";

import Styles from "./post-details.module.css";


type PostDetailsProps = {
  title: string;
  date?: string;
  preview?: any;
  description: any;
  tags?: [];
  className?: string;
  imagePosition?: 'left' | 'top';
  honbun: string;
  chyusyaku: string;
  hosoku: string;
};

const PostDetails: React.memo<PostDetailsProps> = ({
  title,
  date,
  preview,
  description,
  tags,
  className,
  imagePosition,
  honbun,
  chyusyaku,
  hosoku,
  ...props
}) => {
  const addClass: string[] = ['post_details'];

  if (imagePosition == 'left') {
    addClass.push('image_left');
  }

  if (className) {
    addClass.push(className);
  }

  return (
    <PostDetailsWrapper {...props} className={addClass.join(' ')}>
      {imagePosition == 'left' ? (
        <>
        
          {preview == null ? null : (
            <PostPreview className="post_preview">

<h2>目次から探す</h2>

<div>
<ul className={Styles.menu}>
<li>
 <a href="#">SDGsから選ぶ</a>
 <ul>
<div>
   <div className={Styles.sdgdiv}>
 <div><a href="/tags/sdgs-1"><img className={Styles.sdgicon} src="/sdgsicon/sdg_01.png"/></a></div>
 <div><a href="/tags/sdgs-2"><img className={Styles.sdgicon} src="/sdgsicon/sdg_02.png"/></a></div>
 <div><a href="/tags/sdgs-3"><img className={Styles.sdgicon} src="/sdgsicon/sdg_03.png"/></a></div>
 <div><a href="/tags/sdgs-4"><img className={Styles.sdgicon} src="/sdgsicon/sdg_04.png"/></a></div>
 <div><a href="/tags/sdgs-5"><img className={Styles.sdgicon} src="/sdgsicon/sdg_05.png"/></a></div>
 <div><a href="/tags/sdgs-6"><img className={Styles.sdgicon} src="/sdgsicon/sdg_06.png"/></a></div>
 </div><div className={Styles.sdgdiv}>
 <div><a href="/tags/sdgs-7"><img className={Styles.sdgicon} src="/sdgsicon/sdg_07.png"/></a></div>
 <div><a href="/tags/sdgs-8"><img className={Styles.sdgicon} src="/sdgsicon/sdg_08.png"/></a></div>
 <div><a href="/tags/sdgs-9"><img className={Styles.sdgicon} src="/sdgsicon/sdg_09.png"/></a></div>
 <div><a href="/tags/sdgs-10"><img className={Styles.sdgicon} src="/sdgsicon/sdg_10.png"/></a></div>
 <div><a href="/tags/sdgs-11"><img className={Styles.sdgicon} src="/sdgsicon/sdg_11.png"/></a></div>
 <div><a href="/tags/sdgs-12"><img className={Styles.sdgicon} src="/sdgsicon/sdg_12.png"/></a></div>
 </div><div className={Styles.sdgdiv}>
 <div><a href="/tags/sdgs-13"><img className={Styles.sdgicon} src="/sdgsicon/sdg_13.png"/></a></div>
 <div><a href="/tags/sdgs-14"><img className={Styles.sdgicon} src="/sdgsicon/sdg_14.png"/></a></div>
 <div><a href="/tags/sdgs-15"><img className={Styles.sdgicon} src="/sdgsicon/sdg_15.png"/></a></div>
 <div><a href="/tags/sdgs-16"><img className={Styles.sdgicon} src="/sdgsicon/sdg_16.png"/></a></div>
 <div><a href="/tags/sdgs-17"><img className={Styles.sdgicon} src="/sdgsicon/sdg_17.png"/></a></div>
 <div><a href="/tags/sdgs-17"><img className={Styles.sdgicon} src="/sdgsicon/sdg_17.png"/></a></div>
 </div>
 </div>
 </ul>
</li>
<li>
 <a href="#">高校地理総合から選ぶ</a>
 <ul>
 <div><a href="/tags/さまざまな交通網の発達">1-2-2-3 さまざまな交通網の発達</a></div>
 <div><a href="/tags/観光のグローバル化">1-2-2-5 観光のグローバル化
</a></div>
 <div><a href="/tags/発展途上国と先進国の人口問題">2-2-4-2 発展途上国と先進国の人口問題</a></div>
 <div><a href="/tags/世界の都市の発展">2-2-6-1 世界の都市の発展</a></div>
 <div><a href="/tags/発展途上国と先進国の都市">2-2-6-2 発展途上国と先進国の都市</a></div>
 <div><a href="/tags/地理的な課題と地域調査">3-2-1-1 地理的な課題と地域調査</a></div>

 </ul>
</li>
<li>
 <a href="#">高校地理探究から選ぶ</a>
 <ul>
 <div><a href="/tags/工業の立地">1-2-5-2 工業の立地</a></div>
 <div><a href="/tags/消費行動の変化と商業">1-3-1-2 消費行動の変化と商業</a></div>
 <div><a href="/tags/交通網の発達">1-3-2-1 交通網の発達</a></div>
 <div><a href="/tags/日本の観光と変化">1-3-3-2 日本の観光と変化</a></div>
 <div><a href="/tags/日本の人口問題">1-4-2-2 日本の人口問題</a></div>
 <div><a href="/tags/都市圏の拡大と都市の構造">1-4-3-4 都市圏の拡大と都市の構造</a></div>
 <div><a href="/tags/日本の都市・居住問題">1-4-4-3 日本の都市・居住問題</a></div>
 <div><a href="/tags/多文化の共生に向けた取り組み">1-5-2-4 多文化の共生に向けた取り組み</a></div>
 </ul>
</li>
<li>
 <a href="#">出典から選ぶ</a>
 <div><a href="#">など、メニューは増やすことができます</a></div>
</li>
</ul>
</div>


{/* <h2>良い例：福岡県糸島市</h2>
              <CesiumDiv
      dir="/kml"
      file="/sample.kml"
      kmlurl="/kml/sample.kml"
    />
              <p>小売業が公共交通利用圏に集積しており利便性が高い（なおこの画面はイメージです。現時点では全てのページで同じ情報が表示されています）</p> */}


              {/* <Img fluid={preview} alt={title} /> */}
            </PostPreview>
          )}
        </>
      ) : (
        ''
      )}

      {imagePosition == 'top' ? (
        <>
          <PostTitle>{title}</PostTitle>
          <PostDate>{date}</PostDate>
        </>
      ) : (
        ''
      )}

      {imagePosition == 'top' ? (
        <>
          {preview == null ? null : (
            <PostPreview className="post_preview">
              <Img fluid={preview} alt={title} />
            </PostPreview>
          )}
        </>
      ) : (
        ''
      )}
      <PostDescriptionWrapper className="post_des_wrapper">
        {/* {imagePosition == 'left' ? (
          <>


            <PostTitle>{title}</PostTitle>

            <PostDate>{date}</PostDate>
         
          </>
        ) : (
          ''
        )} */}



        {/* <HonbunWrapper>
          <div className="box26">
          <span className="box-title">解説</span>
          <p>{honbun}
          <br/><br/><br/><br/><br/><br/><br/>
          </p>
          </div>
          <div className="brackboard" >TRY!<br/>{chyusyaku}{hosoku}
          <br/><br/><br/><br/><br/><br/><br/>
          </div>
        </HonbunWrapper> */}

        <PostDescription
          dangerouslySetInnerHTML={{ __html: description }}
          className="post_des"
        />
        

        {tags == null ? null : (
          <PostTags>
            {tags.map((tag, index) => (
              <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                {`#${tag}`}
              </Link>
            ))}
          </PostTags>
        )}
      </PostDescriptionWrapper>
    </PostDetailsWrapper>
  );
};

PostDetails.defaultProps = {
  imagePosition: 'top',
};

export default PostDetails;
